import 'dark-mode-toggle'

export default function initDarkModeToggle() {
  const toggle = document.querySelector('dark-mode-toggle')
  if (!toggle) return

  const themeColor = document.querySelector('meta[name="theme-color"]')
  const icon = document.querySelector('link[rel="icon"][type="image/svg+xml"]')
  const colorScheme = document.querySelector('meta[name="color-scheme"]')
  const body = document.body

  toggle.mode === 'dark'
    ? body.classList.add('Dark')
    : body.classList.remove('Dark');

  // Listen for toggle changes (which includes `prefers-color-scheme` changes)
  toggle.addEventListener('colorschemechange', (e) => {
    themeColor.content = getComputedStyle(body).color
    colorScheme.content = e.detail.colorScheme
    body.classList.toggle('Dark', toggle.mode === 'dark');
  })

  // Listen for global changes from the operating system (`prefers-color-scheme` changes)
  const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
  darkModeMediaQuery.addEventListener('change', (e) => {
    const darkModeOn = e.matches
    icon.href = darkModeOn ? icon.dataset.darkIcon  : icon.dataset.lightIcon
  })
}

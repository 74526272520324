import Glide from '@glidejs/glide'

// This creates javascripts/nsjs.css
import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

export default function initCarousel() {
  if (!document.querySelector('.glide')) return

  new Glide('.glide', {
    autoplay: 5000,
    animationDuration: 720,
    hoverpause: true,
    perTouch: 1,
    perView: 1,
  }).mount()
}

export default async function initSocialCard() {
  console.log('init social card')
  document.querySelector('dark-mode-toggle').mode = 'dark'
  const socialCard = document.querySelector('.SocialCard--hidden')
  if (socialCard) {
    socialCard.style.display = 'grid'
  }
  const progressMeter = document.getElementById('progress-meter')
  if (progressMeter) {
    progressMeter.style.display = 'none'
  }
  const delay = ms => new Promise(res => setTimeout(res, ms))
  // 1701 millseconds corresponds w/ --colour-scheme-anim-duration
  await delay(1701)
}

export default function initProgressMeter() {
  const progressMeter = document.querySelector('#progress-meter')
  if (!progressMeter) return

  // scroll to top on click
  progressMeter.addEventListener('click', function(e) {
    document.querySelector('.Header').scrollIntoView({behavior: 'smooth'})
  })
  // scroll to top on when hit enter when focused
  progressMeter.addEventListener('keydown', function(e) {
    if (e.key === 'Enter') {
      document.querySelector('.Header').scrollIntoView({behavior: 'smooth'})
    }
  })

  // show progress
  let visible = false
  const path = document.querySelector('#progress-path')
  const pathLength = path.getTotalLength()
  path.style.strokeDasharray = pathLength + ' ' + pathLength
  path.style.strokeDashoffset = pathLength
  path.getBoundingClientRect()
  window.addEventListener('scroll', function (e) {
    let scrollPercentage = (document.documentElement.scrollTop + document.body.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight)
    const drawLength = pathLength * scrollPercentage
    path.style.strokeDashoffset = pathLength - drawLength
    if (scrollPercentage >= 0.99) {
      path.style.strokeDasharray = 'none'
    } else {
      path.style.strokeDasharray = pathLength + ' ' + pathLength
    }

    // only visible when started scrolling
    if (scrollPercentage > 0.03 && !visible) {
      progressMeter.classList.remove('u-fadeOut')
      progressMeter.classList.add('u-fadeIn')
      visible = true
    } else if (scrollPercentage <= 0.03 && visible) {
      progressMeter.classList.remove('u-fadeIn')
      progressMeter.classList.add('u-fadeOut')
      visible = false
    }
  })
}

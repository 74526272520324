let map;

export default async function initMap() {
  if (!document.querySelector('#map')) return

  const { Map } = await google.maps.importLibrary("maps");
  const mapEl = document.getElementById("map");
  const mapStyles = getComputedStyle(mapEl);
  let landscapeColour = mapStyles.getPropertyValue("--theme-map-landscape-colour");
  let roadColour = mapStyles.getPropertyValue("--theme-map-road-colour");
  let waterColour = mapStyles.getPropertyValue("--theme-map-water-colour");

  let mapOptions = [
    {
      featureType: "all",
      elementType: "labels",
      stylers: [{
        visibility: "off"
      }],
    },
    {
      featureType: "all",
      elementType: "geometry",
      stylers: [{
        color: roadColour.trim()
      }],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [{
        color: landscapeColour.trim()
      }]
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [{
        color: roadColour.trim()
      }]
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{
        color: waterColour.trim()
      }]
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [{
        visibility: "off"
      }]
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [{
        color: roadColour.trim()
      }]
    },
    {
      featureType: "transit.station.airport",
      elementType: "geometry",
      stylers: [{
        visibility: "off"
      }]
    }
  ]

  map = new Map(mapEl, {
    center: { lat: 49.289689, lng: -123.089104 },
    zoom: 13,
    styles: mapOptions,
    disableDefaultUI: true,
    keyboardShortcuts: false,
    scrollwheel: false
  });

  // dark-mode-toggle already has logic for what mode is actually in effect
  const colorScheme = document.querySelector('dark-mode-toggle').mode
  const markerPath = colorScheme === 'dark' ? mapEl.dataset.markerPathDark
                                            : mapEl.dataset.markerPathLight
  const hq = new google.maps.Marker({
    position: { lat: 49.278048, lng: -123.080104 },
    map,
    icon: location.origin + markerPath,
  });
}

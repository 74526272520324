import initNav from '~/javascripts/nav.js'
import initCarousel from '~/javascripts/carousel.js'
import initDarkModeToggle from '~/javascripts/dark_mode_toggle.js'
import initMap from '~/javascripts/map.js'
import initProgressMeter from '~/javascripts/progress_meter.js'
import initSocialCard from '~/javascripts/social_card.js'

document.addEventListener('DOMContentLoaded', function() {
  initNav()
  initCarousel()
  initDarkModeToggle()
  initMap()
  initProgressMeter()
  window.initSocialCard = initSocialCard
})

document.addEventListener('colorschemechange', (e) => {
  // ISSUE: only changes the pin does not change colour prefs
  initMap()
})

